import store from '@/state/store'

const user = store.getters['auth/currentUser']


export default [
    // student Routes
    {
        path: '/',
        name: 'home',
        component: () => import('./views/main/home'),
    },
    {
        path: '/signup',
        name: 'signup',
        meta: {
            beforeResolve(routeTo, routeFrom, next) {

                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({
                        name: 'home'
                    })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
        component: () => import('./views/main/auth/register')
    },
    {
        path: '/signup-confirmation',
        name: 'signup-confirmation',
        meta: {
            beforeResolve(routeTo, routeFrom, next) {

                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({
                        name: 'home'
                    })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
        component: () => import('./views/main/auth/signup-confirmation')
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            beforeResolve(routeTo, routeFrom, next) {

                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({
                        name: 'home'
                    })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
        component: () => import('./views/main/auth/login')
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('./views/main/auth/forgot-password')
    },
    {
        path: '/confirm-reset-password',
        name: 'confirm-reset-password',
        component: () => import('./views/main/auth/confirm-reset-password')
    },
    {
        path: '/user-activation',
        name: 'user-activation',
        component: () => import('./views/main/auth/user-activation')
    },
    {
        path: '/document/details/:slug',
        name: 'document-detail',
        component: () => import('./views/main/document-detail')
    },
    {
        path: '/shopping-cart',
        name: 'shopping-cart',
        component: () => import('./views/main/shopping-cart')
    },
    {
        path: '/payment',
        name: 'payment',
        meta: {
            authRequired: true
        },
        component: () => import('./views/main/payment-page')
    },
    {
        path: '/subject-document/:slug',
        name: 'subject-document',
        component: () => import('./views/main/subject-document')
    },
    {
        path: '/class-subject/:slug',
        name: 'class-subject',
        component: () => import('./views/main/class-subject')
    },
    {
        path: '/profile/:id',
        name: 'profile',
        meta: {
            authRequired: true
        },
        component: () => import('./views/main/profile-page')
    },
    {
        path: '/document-download',
        name: 'document-download',
        meta: {
            authRequired: true
        },
        component: () => import('./views/main/document-download')
    },
    {
        path: '/document/download-file/:slug',
        name: 'download-file',
        meta: {
            authRequired: true
        },
        component: () => import('./views/main/download-file')
    },
    {
        path: '/payment-confirmation',
        name: 'payment-confirmation',
        meta: {
            authRequired: true
        },
        component: () => import('./views/main/payment-confirmation')
    },
    {
        path: '/past-papers',
        name: 'past-papers',
        component: () => import('./views/main/past-papers')
    },
    {
        path: '/offline-payment',
        name: 'offline-payment',
        meta: {
            authRequired: true
        },
        component: () => import('./views/main/offline-payment')
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: () => import('./views/main/privacy-policy')
    },
    {
        path: '/terms-and-condition',
        name: 'terms-condition',
        component: () => import('./views/main/terms-condition')
    },
    {
        path: '/about-us',
        name: 'about-us',
        component: () => import('./views/main/about-us')
    },
    {
        path: '/contact-us',
        name: 'contact-us',
        component: () => import('./views/main/contact-us')
    },
    {
        path: '/subscription-activation',
        name: 'subscription-activation',
        component: () => import('./views/main/confirm-subscription')
    },
    {
        path: '/blog-list',
        name: 'blog-list',
        component: () => import('./views/main/blog-list')
    },
    {
        path: '/post-details/:slug',
        name: 'blog-details',
        component: () => import('./views/main/blog-details')
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'notFound',
        component: () => import('./views/main/404-page')
    },
    {
        path: '/403-permission-denied',
        name: 'unauthorized',
        component: () => import('./views/main/403-unauthorized')
    },
    {
        path: '/translation-service',
        name: 'translation-service',
        component: () => import('./views/main/translation-service')
    },
    {
        path: '/app/somamax/privacy-policy',
        name: 'somamax-privacy-policy',
        component: () => import('./views/main/somamax-privacy-policy')
    },



    // Admin Routes

    {
        path: '/admin',
        name: 'admin',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {

                if (user.is_admin) {
                    next()
                } else {
                    next({
                        name: 'unauthorized'
                    })
                }
            },
        },
        component: () => import('./views/admin/dashboard'),
    },
    {
        path: '/admin/role-permission',
        name: 'role',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {

                if (user.is_admin) {
                    next()
                } else {
                    next({
                        name: 'unauthorized'
                    })
                }
            },
        },
        component: () => import('./views/admin/roles/role')
    },
    {
        path: '/admin/add-role',
        name: 'add-role',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {

                if (user.is_admin) {
                    next()
                } else {
                    next({
                        name: 'unauthorized'
                    })
                }
            },
        },
        component: () => import('./views/admin/roles/add-role')
    },
    {
        path: '/admin/permission/:roleId',
        name: 'permission',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {

                if (user.is_admin) {
                    next()
                } else {
                    next({
                        name: 'unauthorized'
                    })
                }
            },
        },
        component: () => import('./views/admin/roles/permission')
    },
    {
        path: '/admin/users',
        name: 'users',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {

                if (user.is_admin) {
                    next()
                } else {
                    next({
                        name: 'unauthorized'
                    })
                }
            },
        },
        component: () => import('./views/admin/users/user')
    },
    {
        path: '/admin/classes',
        name: 'classes',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {

                if (user.is_admin || user.is_author) {
                    next()
                } else {
                    next({
                        name: 'unauthorized'
                    })
                }
            },
        },
        component: () => import('./views/admin/classes/class')
    },
    {
        path: '/admin/subjects',
        name: 'subjects',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {

                if (user.is_admin || user.is_author) {
                    next()
                } else {
                    next({
                        name: 'unauthorized'
                    })
                }
            },
        },
        component: () => import('./views/admin/subjects/subject')
    },
    {
        path: '/admin/regions',
        name: 'region',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {

                if (user.is_admin) {
                    next()
                } else {
                    next({
                        name: 'unauthorized'
                    })
                }
            },
        },
        component: () => import('./views/admin/regions/region')
    },
    {
        path: '/admin/documents',
        name: 'document',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {

                if (user.is_admin || user.is_author) {
                    next()
                } else {
                    next({
                        name: 'unauthorized'
                    })
                }
            },
        },
        component: () => import('./views/admin/documents/document')
    },
    {
        path: '/admin/inventory',
        name: 'inventory',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {

                if (user.is_admin || user.is_author) {
                    next()
                } else {
                    next({
                        name: 'unauthorized'
                    })
                }
            },
        },
        component: () => import('./views/admin/inventory/inventory')
    },
    {
        path: '/admin/orders',
        name: 'order',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {

                if (user.is_admin) {
                    next()
                } else {
                    next({
                        name: 'unauthorized'
                    })
                }
            },
        },
        component: () => import('./views/admin/orders/order')
    },
    {
        path: '/admin/customers',
        name: 'customer',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {

                if (user.is_admin) {
                    next()
                } else {
                    next({
                        name: 'unauthorized'
                    })
                }
            },
        },
        component: () => import('./views/admin/customers/customer')
    },
    {
        path: '/admin/payments',
        name: 'admin-payment',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {

                if (user.is_admin) {
                    next()
                } else {
                    next({
                        name: 'unauthorized'
                    })
                }
            },
        },
        component: () => import('./views/admin/payments/payment')
    },
    {
        path: '/admin/edit-document/:documentId',
        name: 'edit-document',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {

                if (user.is_admin || user.is_author) {
                    next()
                } else {
                    next({
                        name: 'unauthorized'
                    })
                }
            },
        },
        component: () => import('./views/admin/documents/edit-document')
    },
    {
        path: '/admin/add-document',
        name: 'add-document',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {

                if (user.is_admin || user.is_author) {
                    next()
                } else {
                    next({
                        name: 'unauthorized'
                    })
                }
            },
        },
        component: () => import('./views/admin/documents/add-document')
    },
    {
        path: '/admin/document-details/:id',
        name: 'document-details',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {

                if (user.is_admin || user.is_author) {
                    next()
                } else {
                    next({
                        name: 'unauthorized'
                    })
                }
            },
        },
        component: () => import('./views/admin/documents/document-details')
    },
    {
        path: '/admin/posts',
        name: 'post-list',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {

                if (user.is_admin || user.is_author) {
                    next()
                } else {
                    next({
                        name: 'unauthorized'
                    })
                }
            },
        },
        component: () => import('./views/admin/blog/posts')
    },
    {
        path: '/admin/add-post',
        name: 'add-post',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {

                if (user.is_admin || user.is_author) {
                    next()
                } else {
                    next({
                        name: 'unauthorized'
                    })
                }
            },
        },
        component: () => import('./views/admin/blog/add-post')
    },
    {
        path: '/admin/edit-post/:slug',
        name: 'edit-post',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {

                if (user.is_admin || user.is_author) {
                    next()
                } else {
                    next({
                        name: 'unauthorized'
                    })
                }
            },
        },
        component: () => import('./views/admin/blog/edit-post')
    },
    {
        path: '/admin/order-item/:orderId',
        name: 'order-item',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {

                if (user.is_admin) {
                    next()
                } else {
                    next({
                        name: 'unauthorized'
                    })
                }
            },
        },
        component: () => import('./views/admin/orders/order-item')
    },
    {
        path: '/card-payment-confirmation',
        name: 'card-payment-confirm',
        component: () => import('./views/main/card-payment-confirm')
    },
    {
        path: '/admin/plans',
        name: 'plans',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {

                if (user.is_admin) {
                    next()
                } else {
                    next({
                        name: 'unauthorized'
                    })
                }
            },
        },
        component: () => import('./views/admin/plans/plans')
    }

]