import axios from 'axios';

import { authHeader } from '../auth-header';
import { API_URL } from '../api-utils';

export const reportService = {
    fetchTopCardData,
    fetchHomepageData
}

async function fetchHomepageData() {

    const headers = {
        'Accept': 'application/json'
    }

    return await axios.get(`${API_URL}/reports/home_page_analytics`, {
        headers: headers
    })
}

async function fetchTopCardData() {

    return await axios.get(`${API_URL}/reports/top_card_data`, {
        headers: authHeader()
    })
}

