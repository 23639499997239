import { cartService } from "@/api/services/cart";

let cart = window.localStorage.getItem('cart');

export const state = {
    cartItems: cart ? JSON.parse(cart) : []
}

export const getters = {
    cartItemCount: (state) => {
        return state.cartItems == [] ? 0 : state.cartItems.length
    },
    cartTotalPrice: (state) => {
        let total = 0;
        state.cartItems.forEach(item => {
           total += item.document.price * item.quantity; 
        });
        return total;
    },
    cartItems: (state) => state.cartItems
}


export const mutations = {

    ADD_CART_ITEMS (state, {document, quantity}) {

        let documentInCart = state.cartItems.find(item => {
            return item.document.id == document.id
        })

        if (documentInCart) {
            documentInCart.quantity += quantity;
        } else {
            state.cartItems.push({document, quantity})
        }

        window.localStorage.setItem('cart', JSON.stringify(state.cartItems))
    },
    REMOVE_CART_ITEM: (state, document) => {
        let cartItems = state.cartItems.filter(c => c.document.id !== document.id);
        state.cartItems = cartItems;

        window.localStorage.setItem('cart', JSON.stringify(state.cartItems))
    }
}


export const actions = {

    async makePayment({ commit }, payload) {
        const response = await cartService.Payment(payload)
        return response
    },

    async removeItemAction({ commit }, document) {

        commit('REMOVE_CART_ITEM', document)
    },

    async addToCartAction({ commit }, {document, quantity}) {

        commit('ADD_CART_ITEMS', {document, quantity})
    },
}

