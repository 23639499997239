import { reportService } from "@/api/services/report";


export const state = {
    top_card_data: {},
    homepage_analytics: {}
}


export const getters = {
    top_card_data: state => state.top_card_data,
    homepage_analytics: state => state.homepage_analytics
}


export const mutations = {
    SET_TOP_CARD_DATA: (state, data) => {
        state.top_card_data = data
    },
    SET_HOMEPAGE_DATA: (state, data) => {
        state.homepage_analytics = data
    }
}


export const actions = {

    async fetchHomepageData({ commit }) {
        const response = await reportService.fetchHomepageData()
        commit('SET_HOMEPAGE_DATA', response.data)
    },

    async fetchTopCardData({ commit }) {
        const response = await reportService.fetchTopCardData()
        commit('SET_TOP_CARD_DATA', response.data)
    }
}