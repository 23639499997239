import axios from 'axios';

import { authHeader } from '../auth-header';
import { API_URL } from '../api-utils';

export const regionService = {
    fetchRegion,
    addRegion,
    editRegion,
    deleteRegion
}

async function deleteRegion(regionId) {

    return await axios.delete(`${API_URL}/documents/region/${regionId}/`, { headers: authHeader() })
}


async function editRegion(payload) {

    const body = {
        title: payload['title']
    }

    return await axios.put(`${API_URL}/documents/region/${payload['regionId']}/`, body, { headers: authHeader() })
}

async function addRegion(payload) {

    return await axios.post(`${API_URL}/documents/region/`, payload, { headers: authHeader() })
}


async function fetchRegion() {

    return await axios.get(`${API_URL}/documents/region`, {
        headers: authHeader()
    })
}