import axios from 'axios';

import { authHeader } from '../auth-header';
import { API_URL } from '../api-utils';


export const cartService = {
    Payment
}

async function Payment(payload) {

    if (payload['gateway'] == 'card') {
        return await axios.post(`${API_URL}/payments/selcom_card_payment/`, payload, { headers: authHeader() })
    } else {
        return await axios.post(`${API_URL}/payments/selcom_mobile_payment/`, payload, { headers: authHeader() })
    } 
}