<script>
import appConfig from "@/app.config";
import { useHead } from '@vueuse/head'
import {computed, reactive } from 'vue'

export default {
  setup() {
    const siteData = reactive({
      title: appConfig.title,
      description: appConfig.description,
    })
    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description),
        },
        ],
     
    })
  },
  name: 'app',
}
</script>

<template>
  <div id="app">
    <RouterView />
  </div>
</template>