import { documentService } from "@/api/services/document";


export const state = {
    documents: [],
    document: {},
    document_list: [],
    pub_document: {},
    related_document: [],
    purchased_document: [],
    document_file: [],
    subject_documents: [],
    digital_products: [],
    physical_documents: [],
    filtered_digital_products: []
}

export const getters = {
    allDocument: state => state.documents,
    singleDocument: state => state.document,
    documentList: state => state.document_list,
    document: state => state.pub_document,
    relatedDocuments: state => state.related_document,
    purchasedDocuments: state => state.purchased_document,
    documentFiles: state => state.document_file,
    subject_documents: state => state.subject_documents,
    digital_products: state => state.digital_products,
    physical_documents: state => state.physical_documents,
    filtered_products: state => state.filtered_digital_products
}


export const mutations = {

    SET_DOCUMENTS: (state, documents) => {
        state.documents = documents;
    },

    ADD_DOCUMENT: (state, data) => {
        state.documents.push(data)
    },

    SET_DOCUMENT: (state, document) => {
        state.document = document
    },

    SET_PUB_DOCUMENT: (state, pub_document) => {
        state.pub_document = pub_document
    },

    DELETE_DOCUMENT: (state, documentId) => {
        let documents = state.documents.filter(c => c.id !== documentId);
        state.documents = documents;
    },

    SET_DOCUMENT_LIST: (state, lists) => {
        state.document_list.push(...lists)
    },

    SET_RELATED_DOCUMENTS: (state, related_documents) => {
        state.related_document = related_documents
    },

    SET_PURCHASED_DOCUMENT: (state, documents) => {
        state.purchased_document = documents
    },

    SET_DOCUMENT_FILE: (state, files) => {
        state.document_file = files
    },

    SET_SUBJECT_DOCUMENTS: (state, sub_documents) => {
        state.subject_documents = sub_documents
    },

    SET_DIGITAL_PRODUCT: (state, documents) => {
        state.digital_products.push(...documents)
    },

    SET_PHYSICAL_PRODUCT: (state, documents) => {
        state.physical_documents.push(...documents)
    },

    FILTER_DIGITAL_DOCUMENTS: (state, documents) => {
        state.digital_products = documents
    },

    CHANGE_STATUS: (state, data) => {
        const item = state.documents.find(item => item.id === data.id);
        Object.assign(item, data);
    }
}


export const actions = {

    async changeStatus({ commit }, payload) {
        const response = await documentService.changeStatus(payload)
        commit('CHANGE_STATUS', response.data)
    },

    async filterDigitalDocument({ commit}, payload) {
        const response = await documentService.digitalDocument(payload)
        commit('FILTER_DIGITAL_DOCUMENTS', response.data.results)
        return response.data
    },

    async physicalDocument({ commit }, currentPage) {
        const response = await documentService.physicalDocument(currentPage)
        commit('SET_PHYSICAL_PRODUCT', response.data.results)
        return response.data
    },

    async digitalDocument({ commit }, payload) {
        const response = await documentService.digitalDocument(payload)
        commit('SET_DIGITAL_PRODUCT', response.data.results)
        return response.data
    },

    async subjectDocuments({ commit }, payload) {
        const response = await documentService.subjectDocument(payload)
        commit('SET_SUBJECT_DOCUMENTS', response.data)
        
        return response.data
    },

    async downloadFile({ commit}, payload) {
        const response = await documentService.downloadFile(payload)
        return response
    },

    async fetchDocumentFile({ commit }, slug) {
        const response = await documentService.fetchDocumentFile(slug)
        commit('SET_DOCUMENT_FILE', response.data)
    },

    async purchasedDocument({ commit }) {
        const response = await documentService.purchasedDocument()

        commit('SET_PURCHASED_DOCUMENT', response.data)
    },

    async relatedDocument({commit}, slug) {
        const response = await documentService.relatedDocument(slug)
        commit('SET_RELATED_DOCUMENTS', response.data)
    },

    async documentDetail({ commit }, slug) {
        const response = await documentService.documentDetail(slug);
        commit('SET_PUB_DOCUMENT', response.data)
        return response.data
    },

    async searchDocument({ commit }, payload) {

        const response = await documentService.searchDocument(payload)

        return response.data
    },

    async listDocument({ commit }, currentPage) {

        const response = await documentService.listDocument(currentPage)

        commit('SET_DOCUMENT_LIST', response.data.results)

        return response.data;
    },

    async deleteDocumentFile({ commit }, fileId) {
        
        await documentService.deleteDocumentFile(fileId)
    },

    async addDocumentFile({ commit }, payload) {

        await documentService.addDocumentFile(payload)

    },

    async deleteDocument({ commit}, documentId) {

        const response = await documentService.deleteDocument(documentId)

        commit('DELETE_DOCUMENT', documentId)
    },

    async editDocument({ commit }, {payload, documentId}) {

        const response = await documentService.editDocument(payload, documentId)

        return response.data
    },

    async fetchSingleDocument({ commit }, documentId) {

        const response = await documentService.fetchSingleDocument(documentId)

        commit('SET_DOCUMENT', response.data)

        return response.data.document
    },

    async addDocument({ commit }, payload) {

        const response = await documentService.addDocument(payload)

        commit('ADD_DOCUMENT', response.data)
    },

    async fetchDocument({ commit }, params) {

        const response = await documentService.fetchDocument(params)

        commit('SET_DOCUMENTS', response.data.results)

        return response.data
    }
}