import axios from 'axios';

import { authHeader } from '../auth-header';
import { API_URL } from '../api-utils';


export const userService = {
    fetchUser,
    addUser,
    editUser,
    lockUser
}

async function lockUser(payload) {

    const body = {
        status: payload['status'],
    }

    return await axios.post(`${API_URL}/auth/accounts/users/${payload['userId']}/lock`, body, { headers: authHeader() })
}

async function editUser(payload) {

    const body = {
        name: payload['name'],
        email: payload['email'],
        phone: payload['phone'],
        role_id: payload['role']
    }

    return await axios.put(`${API_URL}/auth/accounts/users/${payload['userId']}/edit`, body, { headers: authHeader() })
}


async function addUser(payload) {

    return await axios.post(`${API_URL}/auth/accounts/users/`, payload, { headers: authHeader() })
}


async function fetchUser(lazyParams) {


    const page = lazyParams['page'] + 1

    return await axios.get(`${API_URL}/auth/accounts/users/?page=${page}&query=${lazyParams['query']}`, {
        headers: authHeader()
    })
}