import { createStore, createLogger } from 'vuex'

import modules from './modules'

const debug = process.env.NODE_ENV !== 'production'


// Create a new store instance.
const store = createStore({
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: debug,
  plugins: debug ? [createLogger()] : []
})


export default store 