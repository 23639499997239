import axios from 'axios';
import { authHeader } from '../auth-header';
import { API_URL } from '../api-utils';
import { fileUploadHeader } from '../file-upload';

export const blogService = {
    blogList,
    postDetails,
    postComment,
    addPost,
    deletePost,
    editPost
}

async function editPost(payload) {

    return await axios.put(`${API_URL}/blog/posts/${payload['id']}/edit/`, payload['formData'], { headers: fileUploadHeader() })
}

async function deletePost(id) {
    return await axios.delete(`${API_URL}/blog/posts/${id}/delete/`, { headers: authHeader() })
}

async function addPost(payload) {
    return await axios.post(`${API_URL}/blog/posts/`, payload, { headers: fileUploadHeader() })
}

async function postComment(payload) {

    return await axios.post(`${API_URL}/blog/comments/`, payload, { headers: authHeader() })
}

async function postDetails(slug) {
    const headers = {
        'Accept': 'application/json'
    }

    return await axios.get(`${API_URL}/blog/post_details/${slug}/`, {
        headers: headers
    })
}

async function blogList(currentPage) {

    const headers = {
        'Accept': 'application/json'
    }

    return await axios.get(`${API_URL}/blog/posts/?page=${currentPage}`, {
        headers: headers
    })
}