import axios from 'axios';
import { authHeader } from '../auth-header';
import { API_URL } from '../api-utils';


export const orderService = {
    fetchOrders,
    deleteOrder,
    payOrder,
    orderItems
}


async function orderItems(orderId) {

    return await axios.get(`${API_URL}/orders/list_order_item/${orderId}`, {
        headers: authHeader()
    })
}

async function payOrder(payload) {
    return await axios.post(`${API_URL}/orders/orders/`, payload, {
        headers: authHeader()
    })
}

async function deleteOrder(id) {
    return await axios.delete(`${API_URL}/orders/order/${id}/delete/`, {
        headers: authHeader()
    })
}

async function fetchOrders() {

    return await axios.get(`${API_URL}/orders/orders/`, {
        headers: authHeader()
    })
}