import { orderService } from "@/api/services/order"

export const state = {
    orders: [],
    order_items: []
}

export const getters = {
    orders: state => state.orders,
    order_items: state => state.order_items
}


export const mutations = {
    SET_ORDERS: (state, orders) => {
        state.orders = orders
    },
    DELETE_ORDER: (state, id) => {
        let orders = state.orders.filter(c => c.id !== id);
        state.orders = orders;
    },
    PAY_ORDER: (state, order) => {
        var item = state.orders.find(item => item.id === order.id);
        Object.assign(item, order);
    },
    SET_ORDER_ITEM: (state, order_items) => {
        state.order_items = order_items
    }
}


export const actions = {

    async fetchOrderItem({ commit }, orderId) {
        const response = await orderService.orderItems(orderId)
        commit('SET_ORDER_ITEM', response.data)
    },

    async payOrder({ commit }, payload) {
        const response = await orderService.payOrder(payload)
        commit('PAY_ORDER', response.data)
    },

    async deleteOrder({ commit }, id) {
        const response = await orderService.deleteOrder(id)
        commit('DELETE_ORDER', id)
    },

    async fetchOrders({ commit }) {
        const response = await orderService.fetchOrders()
        commit('SET_ORDERS', response.data)
    }
}