import { createApp } from 'vue'
import store from '@/state/store'
import App from './App.vue'
import router from './router/index'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css';
import VueGtag from "vue-gtag";
import { createHead } from '@vueuse/head'
import vueCountryRegionSelect from 'vue3-country-region-select'



import "bootstrap"

import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import VueSocialChat from 'vue-social-chat'
import 'vue-social-chat/dist/style.css'

//theme
import "primevue/resources/themes/lara-light-indigo/theme.css";     
    
//core
import "primevue/resources/primevue.min.css";

//icons
import "primeicons/primeicons.css";


const app = createApp(App)
const head = createHead()

app.use(router)
app.use(store)
app.use(vueCountryRegionSelect)

app.use(PrimeVue, { ripple: true });
app.use(ToastService);

app.use(VueGtag, {
    config: {
        id: "G-6Q0DRNHGVJ",
        params: {
            anonymize_ip: true
        }
    },
}, router);

app.use(head)
app.use(VueSocialChat)

const options = {
    confirmButtonColor: '#d33',
    cancelButtonColor: '#010D3C',
};

app.use(VueSweetalert2, options)

// app.directive('can', (el, binding, vnode) => {

//         var permissions = JSON.parse(localStorage.getItem('user'))['permissions'];
        
//         if (permissions.includes(binding.value)) {
//             return vnode.el.hidden = false;
//         } else {           
//             return vnode.el.hidden = true;
//         }

//     }
// )

app.mount('#app')
