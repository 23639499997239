import axios from 'axios';

import { authHeader } from '../auth-header';
import { API_URL } from '../api-utils';

export const subjectService = {
    fetchSubject,
    addSubject,
    editSubject,
    deleteSubject
}

async function deleteSubject(subjectId) {

    return await axios.delete(`${API_URL}/documents/subject/${subjectId}/`, { headers: authHeader() })
}


async function editSubject(payload) {

    const body = {
        title: payload['title'],
        classe: payload['classe']
    }

    return await axios.put(`${API_URL}/documents/subject/${payload['subjectId']}/`, body, { headers: authHeader() })
}

async function addSubject(payload) {

    return await axios.post(`${API_URL}/documents/subject/`, payload, { headers: authHeader() })
}


async function fetchSubject() {

    return await axios.get(`${API_URL}/documents/subject`, {
        headers: authHeader()
    })
}

