import axios from 'axios';
import { authHeader } from '../auth-header';
import { API_URL } from '../api-utils';

export const paymentService = {
    listPayments
}

async function listPayments() {
    return await axios.get(`${API_URL}/payments/list_payments/`, {
        headers: authHeader()
    })
}