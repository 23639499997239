import axios from 'axios';
import { API_URL } from '../api-utils';

export const marketingService = {
    sendEnquiry,
    subscribe,
    confirmSubscribe
}

async function confirmSubscribe(payload) {

    const headers = {
        'Content-Type': 'application/json'
    }

    return await axios.post(`${API_URL}/marketing/confirm_email/`, payload, headers)
}


async function subscribe(payload) {

    const headers = {
        'Content-Type': 'application/json'
    }

    return await axios.post(`${API_URL}/marketing/subscribe/`, payload, headers)
}


async function sendEnquiry(payload) {

    const headers = {
        'Content-Type': 'application/json'
    }

    return await axios.post(`${API_URL}/marketing/send_enquiry/`, payload, headers)
}