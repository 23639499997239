import { inventoryService } from "@/api/services/inventory";

export const state = {
    inventories: []
}


export const getters = {
    allInventory: state => state.inventories
}

export const mutations = {
    SET_INVENTORIES: (state, inventories) => {
        state.inventories = inventories;
    },
    ADD_INVENTORY: (state, data) => {
        var item = state.inventories.find(item => item.id === data.id);
        Object.assign(item, data);
    },
}


export const actions = {

    async addInventory({ commit }, payload) {

        const response = await inventoryService.addInventory(payload)

        commit('ADD_INVENTORY', response.data)
    },


    async fetchInventory({ commit }) {

        const response = await inventoryService.fetchInventory()

        commit('SET_INVENTORIES', response.data)
    }
}