import { permissionService } from "@/api/services/permission";


export const state = {
    permissions_not_in_role: [],
    permissions_in_role: []
}

export const getters = {
    allPermissionsNotInRole: state => state.permissions_not_in_role,
    allPermissionsInRole: state => state.permissions_in_role
}


export const mutations = {
    SET_PERMISSION_NOT_IN_ROLE: (state, permissions_not_in_role) => {
        state.permissions_not_in_role = permissions_not_in_role;
    },
    SET_PERMISSION_IN_ROLE: (state, permissions_in_role) => {
        state.permissions_in_role = permissions_in_role;
    },

    ADD_ROLE_PERMISSION: (state, data) => {


        data.forEach(d => {
            state.permissions_in_role.push(d)
        })

        data.forEach(d => {
            state.permissions_not_in_role.unset(d)
        })



        // Array.prototype.push.apply(state.permissions_in_role, data)

        
        // state.permissions_not_in_role = state.permissions_not_in_role.filter( x => !data.filter( y => y.id === x.id).length)
    },

    REMOVE_ROLE_PERMISSION: (state, data) => {
        state.permissions_in_role = state.permissions_in_role.filter( x => !data.filter( y => y.id === x.id).length)
        Array.prototype.push.apply(state.permissions_not_in_role, data)
    },
}

export const actions = {

    async removeRolePermission({ commit }, payload) {

        const response = await permissionService.removeRolePermission(payload)

        commit('REMOVE_ROLE_PERMISSION', response.data)
    },


    async addRolePermission({ commit }, payload) {

        const response = await permissionService.addRolePermission(payload)

        commit('ADD_ROLE_PERMISSION', response.data)
    },

    async fetchPermissionInRole({ commit }, roleId) {

        const response = await permissionService.fetchPermissionInRole(roleId)

        commit('SET_PERMISSION_IN_ROLE', response.data)
    },

    async fetchPermissionNotInRole({ commit }, roleId) {

        const response = await permissionService.fetchPermissionNotInRole(roleId)

        commit('SET_PERMISSION_NOT_IN_ROLE', response.data)
    }
}