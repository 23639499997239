import { subjectService } from "@/api/services/subject";

export const state = {
    subjects: []
}


export const getters = {
    allSubject: state => state.subjects
}

export const mutations = {
    SET_SUBJECTS: (state, subjects) => {
        state.subjects = subjects;
    },

    ADD_SUBJECT: (state, data) => {
        state.subjects.push(data)
    },

    EDIT_SUBJECT: (state, data) => {
        var item = state.subjects.find(item => item.id === data.id);
        Object.assign(item, data);
    },

    DELETE_SUBJECT: (state, subjectId) => {
        let subjects = state.subjects.filter(c => c.id !== subjectId);
        state.subjects = subjects;
    },
}

export const actions = {

    deleteSubject: async ({ commit}, subjectId) => {

        const response = await subjectService.deleteSubject(subjectId)

        commit('DELETE_SUBJECT', subjectId)
    },

    editSubject: async ({ commit }, payload) => {

        const response = await subjectService.editSubject(payload)

        commit('EDIT_SUBJECT', response.data)
    },

    async addSubject({ commit }, payload) {

        const response = await subjectService.addSubject(payload)

        commit('ADD_SUBJECT', response.data)
    },

    async fetchSubject({ commit }) {

        const response = await subjectService.fetchSubject()

        commit('SET_SUBJECTS', response.data)
    }
}