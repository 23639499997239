import { blogService } from "@/api/services/blog";

export const state = {
    posts: [],
    post: {},
}


export const getters = {
    posts: state => state.posts,
    post: state => state.post
}


export const mutations = {
    SET_BLOG_LIST: (state, posts) => {
        state.posts.push(...posts)
    },
    SET_POST_DETAIL: (state, post) => {
        state.post = post
    },
    ADD_COMMENT: (state, comment) => {
        state.post.comments.unshift(comment)
    },
    DELETE_POST: (state, id) => {
        let posts = state.posts.filter(c => c.id !== id);
        state.posts = posts;
    }
}


export const actions = {

    async editPost({ commit }, payload) {
        const response = await blogService.editPost(payload)
        return response.data
    },

    async deletePost({ commit }, id) {
        const response = await blogService.deletePost(id)
        commit('DELETE_POST', id)
    },

    async addPost({ commit }, payload) {
        const response = await blogService.addPost(payload)
        return response.data
    },

    async postComment({ commit }, payload) {
        const response = await blogService.postComment(payload)
        commit('ADD_COMMENT', response.data)
    },

    async fetchPost({ commit }, slug) {
        const response = await blogService.postDetails(slug)
        commit('SET_POST_DETAIL', response.data)
    },

    async fetchPosts({ commit }, currentPage) {
        const response = await blogService.blogList(currentPage)
        commit("SET_BLOG_LIST", response.data.results)
        return response.data
    }
}

