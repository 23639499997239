import axios from 'axios';

import { authHeader } from '../auth-header';
import { API_URL } from '../api-utils';

export const permissionService = {
    fetchPermissionNotInRole,
    fetchPermissionInRole,
    addRolePermission,
    removeRolePermission
}


async function removeRolePermission(payload) {

    return await axios.post(`${API_URL}/auth/accounts/groups/remove_role_permissions`, payload, { headers: authHeader() })
}


async function addRolePermission(payload) {

    return await axios.post(`${API_URL}/auth/accounts/groups/add_role_permissions`, payload, { headers: authHeader() })
}


async function fetchPermissionInRole(roleId) {

    return await axios.get(`${API_URL}/auth/accounts/groups/permission_in_role`, {
        headers: authHeader(),
        params: {
            role_id: roleId
        }
    })

}

async function fetchPermissionNotInRole(roleId) {

    return await axios.get(`${API_URL}/auth/accounts/groups/permission_not_in_role`, {
        headers: authHeader(),
        params: {
            role_id: roleId
        }
    })

}