import { roleService } from "@/api/services/role";

export const state = {
    roles: []
}


export const getters = {
    allRoles: state => state.roles
}

export const mutations = {
    SET_ROLES: (state, roles) => {
        state.roles = roles;
    },
    ADD_ROLE: (state, data) => {
        state.roles.push(data)
    },
    EDIT_ROLE: (state, data) => {
        const item = state.roles.find(item => item.id === data.id);
        Object.assign(item, data);
    }
}

export const actions = {

    async editRole({ commit }, payload) {

        const response = await roleService.editRole(payload)

        commit('EDIT_ROLE', response.data)
    },

    async addRole({ commit }, payload) {

        const response = await roleService.addRole(payload)

        commit('ADD_ROLE', response.data)
    },

    async fetchRole({ commit }) {

        const response = await roleService.fetchRole()

        commit('SET_ROLES', response.data)
    }
}