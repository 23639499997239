import { authService } from "@/api/services/auth";

export const state = {
    currentUser: JSON.parse(localStorage.getItem('user')),
    profile: {}
}

export const mutations = {
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue
        saveState('auth.currentUser', newValue)
    },
    UPDATE_PROFILE: (state, profile) => {
        state.profile = profile
    },
    SET_PROFILE: (state, profile) => {
        state.profile = profile
    }
}

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.currentUser
    },
    currentUser(state) {
        return state.currentUser
    },
    profileDetail(state) {
        return state.profile
    }
}

export const actions = {

    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars
    init({ state, dispatch }) {
        dispatch('validate')
    },

    async deactivateAccount({ commit}, payload) {
        const response = await authService.deactivateAccount(payload)
        return response.data
    },


    async profileDetail({ commit, dispatch}) {

        dispatch('validate')

        const response = await authService.profileDetail()
        commit('SET_PROFILE', response.data)
    },


    async updateProfile({ commit, dispatch }, payload) {

        dispatch('validate')

        const response = await authService.updateProfile(payload)
        commit('UPDATE_PROFILE', response.data)
    },

    async changePassword({ commit }, payload) {
        const response = await authService.changePassword(payload)
        return response
    },

    async activateAccount({ commit }, payload) {
        const response = await authService.activateAccount(payload)
        return response
    },

    async comfirmResetPassword({ commit}, payload) {
        const response = await authService.comfirmResetPassword(payload)
        return response
    },

    async signUp({ commit }, payload) {
        const response = await authService.signup(payload)
        console.log(response)
        return response
    },

    async forgotPassword({ commit }, payload) {
        const response = await authService.forgotpassword(payload)
        return response
    },

    // Logs in the current user.
    async logIn({ commit, dispatch, getters }, { phone, password } = {}) {

        if (getters.loggedIn) return dispatch('validate')

        const response = await authService.login(phone, password)
        const user = response

        commit('SET_CURRENT_USER', user)

        return user
    },

    // Logs out the current user.
    logOut({ commit }) {

        // eslint-disable-next-line no-unused-vars
        commit('SET_CURRENT_USER', null)

        return new Promise((resolve, reject) => {
            localStorage.removeItem('user')
        });
    },


    // Validates the current user's token and refreshes it
    // with new data from the API.
    // eslint-disable-next-line no-unused-vars
    validate: async ({ commit, state }) => {

        if (!state.currentUser) return Promise.resolve(null)

        const userData = JSON.parse(localStorage.getItem('user'))

        const res = await authService.validateToken(userData['access']);

        if (res == 200) {
            var user = userData
        } else if (res == 401) {
            var user = null
        } else {

            const response = await authService.refreshToken(userData['refresh']);

            const user = response.data
        }

        commit('SET_CURRENT_USER', user)
        
        return user;
    }
}


// ===
// Private helpers
// ===

function saveState(key, state) {

    window.localStorage.setItem(key, JSON.stringify(state))

  }

