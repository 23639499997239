import { marketingService } from '@/api/services/marketing';

export const state = {

}

export const getters = {

}


export const mutations = {

}


export const actions = {
    async confirmSubscribe({ commit }, payload) {
        const response = await marketingService.confirmSubscribe(payload)
        return response
    },

    
    async subscribe({ commit }, payload) {
        const response = await marketingService.subscribe(payload)
        return response
    },

    async sendEnquiry({ commit }, payload) {
        const response = await marketingService.sendEnquiry(payload)
        console.log(response)
        return response
    }
}