import axios from 'axios';

import { authHeader } from '../auth-header';
import { API_URL } from '../api-utils';

export const inventoryService = {
    fetchInventory,
    addInventory
}

async function addInventory(payload) {

    return await axios.post(`${API_URL}/documents/inventory/add/`, payload, { headers: authHeader() })
}


async function fetchInventory() {

    return await axios.get(`${API_URL}/documents/inventory`, {
        headers: authHeader()
    })
}