import { regionService } from "@/api/services/region";

export const state = {
    regions: []
}


export const getters = {
    allRegion: state => state.regions
}

export const mutations = {
    SET_REGIONS: (state, regions) => {
        state.regions = regions;
    },

    ADD_REGION: (state, data) => {
        state.regions.push(data)
    },

    EDIT_REGION: (state, data) => {
        var item = state.regions.find(item => item.id === data.id);
        Object.assign(item, data);
    },

    DELETE_REGION: (state, regionId) => {
        let regions = state.regions.filter(c => c.id !== regionId);
        state.regions = regions;
    },
}

export const actions = {

    deleteRegion: async ({ commit}, regionId) => {

        const response = await regionService.deleteRegion(regionId)

        commit('DELETE_REGION', regionId)
    },

    editRegion: async ({ commit }, payload) => {

        const response = await regionService.editRegion(payload)

        commit('EDIT_REGION', response.data)
    },

    async addRegion({ commit }, payload) {

        const response = await regionService.addRegion(payload)

        commit('ADD_REGION', response.data)
    },

    async fetchRegion({ commit }) {

        const response = await regionService.fetchRegion()

        commit('SET_REGIONS', response.data)
    }
}