import axios from 'axios';

import { authHeader } from '../auth-header';
import { API_URL } from '../api-utils';

export const classService = {
    fetchClass,
    addClass,
    editClass,
    deleteClass,
    classSubject
}

// pub
async function classSubject() {
    const headers = {
        'Accept': 'application/json'
    }

    return await axios.get(`${API_URL}/documents/class_subject`, {
        headers: headers
    })
}

async function deleteClass(classId) {

    return await axios.delete(`${API_URL}/documents/class/${classId}/`, { headers: authHeader() })
}

async function editClass(payload) {

    const body = {
        title: payload['title'],
    }

    return await axios.put(`${API_URL}/documents/class/${payload['classId']}/`, body, { headers: authHeader() })
}


async function addClass(payload) {

    return await axios.post(`${API_URL}/documents/class/`, payload, { headers: authHeader() })
}


async function fetchClass() {

    return await axios.get(`${API_URL}/documents/class`, {
        headers: authHeader()
    })
}

