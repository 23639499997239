import axios from 'axios';

import { authHeader } from '../auth-header';
import { API_URL } from '../api-utils';
import { fileUploadHeader } from '../file-upload';


export const authService = {
    login,
    signup,
    refreshToken,
    validateToken,
    updateProfile,
    profileDetail,
    forgotpassword,
    comfirmResetPassword,
    activateAccount,
    changePassword,
    deactivateAccount
}

async function deactivateAccount(payload) {
    return await axios.post(`${API_URL}/auth/accounts/deactivate_account/`, payload, {
        headers: authHeader()
    })
}

async function updateProfile(payload) {
    return await axios.put(`${API_URL}/auth/accounts/profile/`, payload, { headers: fileUploadHeader() })
}

async function profileDetail() {
    return await axios.get(`${API_URL}/auth/accounts/profile/`, { headers: authHeader() })
}

async function validateToken(token) {

    const headers = {
        'Content-Type': 'application/json'
    }

    const payload = {
        token: token
    }

    return await axios.post(`${API_URL}/auth/jwt/verify/`, payload, headers).
        then(function (response) {
            return response['status']
        }).
        catch(function (error) {
            return error['response']['status']
        })
}

async function refreshToken(refresh) {

    const headers = {
        'Content-Type': 'application/json'
    }

    const payload = {
        refresh: refresh
    }

    return await axios.post(`${API_URL}/auth/jwt/refresh/`, payload, headers)
}


async function changePassword(payload) {
    return await axios.post(`${API_URL}/auth/users/set_password/`, payload, {
        headers: authHeader()
    })
}


async function activateAccount(payload) {
    const headers = {
        'Content-Type': 'application/json'
    }
    return await axios.post(`${API_URL}/auth/users/activation/`, payload, headers)
}


async function comfirmResetPassword(payload) {
    const headers = {
        'Content-Type': 'application/json'
    }
    return await axios.post(`${API_URL}/auth/users/reset_password_confirm/`, payload, headers)
}


async function forgotpassword(payload) {
    const headers = {
        'Content-Type': 'application/json'
    }
    return await axios.post(`${API_URL}/auth/users/reset_password/`, payload, headers)
}

async function signup(payload) {
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
    return await axios.post(`${API_URL}/auth/users/`, payload, headers)
}

async function login(phone, password) {

    const headers = {
        'Content-Type': 'application/json'
    }

    const payload = {
        phone: phone,
        password: password
    }

    return await axios.post(`${API_URL}/auth/accounts/login/`, payload, headers)
        .then(function (response) {

            const user = response.data

            if (user['access']) {
                localStorage.setItem('user', JSON.stringify(user));
            }
            return user;
        })
}

