import { classService } from "@/api/services/class";

export const state = {
    classes: [],
    class_subject: []
}


export const getters = {
    allClasses: state => state.classes,
    classSubjects: state => state.class_subject
}

export const mutations = {
    SET_CLASSES: (state, classes) => {
        state.classes = classes;
    },

    ADD_CLASS: (state, data) => {
        state.classes.push(data)
    },

    EDIT_CLASS: (state, data) => {
        var item = state.classes.find(item => item.id === data.id);
        Object.assign(item, data);
    },

    DELETE_CLASS: (state, classId) => {
        let classes = state.classes.filter(c => c.id !== classId);
        state.classes = classes;
    },

    SET_CLASS_SUBJECT: (state, class_subject) => {
        state.class_subject = class_subject
    },
}


export const actions = {

    async classSubject({ commit}) {
        const response = await classService.classSubject()

        commit('SET_CLASS_SUBJECT', response.data)
    },

    deleteClass: async ({ commit}, classId) => {

        const response = await classService.deleteClass(classId)

        commit('DELETE_CLASS', classId)
    },

    editClass: async ({ commit }, payload) => {

        const response = await classService.editClass(payload)

        commit('EDIT_CLASS', response.data)
    },

    async addClass({ commit }, payload) {

        const response = await classService.addClass(payload)

        commit('ADD_CLASS', response.data)
    },


    async fetchClass({ commit }) {

        const response = await classService.fetchClass()

        commit('SET_CLASSES', response.data)
    }
}

