import { userService } from "@/api/services/user";


export const state = {
    users: []
}


export const getters = {
    allUsers: state => state.users
}


export const mutations = {
    SET_USERS: (state, users) => {
        state.users = users;
    },

    ADD_USER: (state, data) => {
        state.users.push(data)
    },

    EDIT_USER: (state, data) => {
        var item = state.users.find(item => item.id === data.id);
        Object.assign(item, data);
    },
    LOCK_USER: (state, data) => {
        var item = state.users.find(item => item.id === data.id);
        Object.assign(item, data);
    }
}


export const actions = {

    lockUser: async ({ commit }, payload) => {
        const response = await userService.lockUser(payload)

        commit('LOCK_USER', response.data)
    },

    editUser: async ({ commit }, payload) => {

        const response = await userService.editUser(payload)

        commit('EDIT_USER', response.data)
    },

    async addUser({ commit }, payload) {

        const response = await userService.addUser(payload)

        commit('ADD_USER', response.data)
    },

    async fetchUsers({ commit }, currentPage) {

        const response = await userService.fetchUser(currentPage)

        commit('SET_USERS', response.data.results)

        return response.data
    }
}