import axios from 'axios';

import { authHeader } from '../auth-header';
import { API_URL } from '../api-utils';

export const roleService = {
    fetchRole,
    addRole,
    editRole,
    deleteRole
}

async function deleteRole(id) {

    return await axios.delete(`${API_URL}/auth/accounts/groups/${id}/delete/`, { headers: authHeader() })
}

async function editRole(data) {

    const payload = {
        name: data['name'],
    }

    return await axios.put(`${API_URL}/auth/accounts/groups/${data['roleId']}/edit`, payload, { headers: authHeader() })
}


async function addRole(payload) {

    return await axios.post(`${API_URL}/auth/accounts/groups/create`, payload, { headers: authHeader() })
}

async function fetchRole() {

    return await axios.get(`${API_URL}/auth/accounts/groups`, {
        headers: authHeader()
    })

}