import axios from 'axios';

import { authHeader } from '../auth-header';
import { API_URL } from '../api-utils';

export const planService = {
    fetchPlan
}


async function fetchPlan() {

    return await axios.get(`${API_URL}/subscriptions/plans/`, {
        headers: authHeader()
    })

}