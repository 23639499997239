import { planService } from "@/api/services/plan";


export const state = {
    plans: []
}


export const getters = {
    plans: state => state.plans
}


export const mutations = {
    SET_PLANS: (state, plans) => {
        state.plans = plans
    }
}


export const actions = {
    async fetchPlan({ commit }) {
        const response = await planService.fetchPlan()
        commit('SET_PLANS', response.data)
    }
}