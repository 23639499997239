import { paymentService } from "@/api/services/payment";


export const state = {
    payments: []
}


export const getters = {
    payments: state => state.payments
}


export const mutations = {
    SET_PAYMENTS: (state, payments) => {
        state.payments = payments
    }
}


export const actions = {

    async fetchPayments({ commit }) {
        const response = await paymentService.listPayments()
        commit('SET_PAYMENTS', response.data)
    }
}